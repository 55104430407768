<template>
  <app-modal
    class="new-partnership-modal"
    v-bind="$attrs"
    :loading="loading"
    :destroy-on-close="true"
    v-on="$listeners"
    @confirm="addNewPartnership">
    <template #title>
      <app-text weight="semi-bold" class="tw-text-jb-ink">
        Add New Partnership
      </app-text>
    </template>
    <template #message>
      <app-text size="sm" weight="medium" class="tw-text-jb-grey-700">
        Add a new partner to your organization by searching the name of the organization below.
      </app-text>
      <div class="tw-mb-space-2 tw-mt-space-4">
        <div class="tw-mb-space-4">
          <app-text type="div" weight="medium" class="tw-text-jb-grey-700 tw-mb-space-1 tw-text-xs">
            Filter Organization
          </app-text>
          <app-select
            v-model="orgFilter"
            placeholder="Search"
            class="tw-w-[200px] tw-block"
            :data="orgTypes">
            <template slot="option" slot-scope="row">
              <div class="tw-flex tw-items-center">
                {{ row.item.name }}
              </div>
            </template>
          </app-select>
        </div>

        <el-form :model="newPartnershipForm" @submit.prevent.native>
          <el-form-item prop="org" :error="error">
            <app-text type="div" weight="medium" class="tw-text-jb-grey-700 tw-mb-space-1 tw-text-xs">
              Organization
            </app-text>
            <app-select
              ref="select"
              v-model="newPartnershipForm.org"
              :filterable="true"
              placeholder="Search"
              :appended-to-div="true"
              :data="orgEntries"
              class="org-select"
              popper-class="org-select-popover"
              @visible-change="error = ''">
              <template slot="prefix">
                <search-icon class="tw-w-space-4 tw-h-space-4 tw-text-jb-grey-400" />
              </template>
              <template slot="option" slot-scope="row">
                <div class="tw-flex tw-items-center">
                  {{ row.item.name }}
                </div>
              </template>
            </app-select>
            <app-text slot="error" type="div" size="sm" class="tw-text-jb-red-dark tw-mt-space-1 tw-font-medium">
              <i class="el-icon-warning tw-mr-1" />
              {{ error }}
            </app-text>
          </el-form-item>
        </el-form>
      </div>
    </template>
  </app-modal>
</template>

<script>
import AppModal from '@/components/AppModal';
import AppSelect from '@/components/AppSelect';
import AppText from '@/components/AppText';
import SearchIcon from '@/assets/svg/search-icon.svg';

export default {
  name: 'NewPartnershipModal',
  components: { AppModal, AppSelect, AppText, SearchIcon },
  data() {
    return {
      loading: false,
      error: '',
      orgFilter: '',
      orgEntries: [
        { name: 'Carousel', id: '1' },
        { name: 'Admin', id: '2' },
        { name: 'JB ADMIN', id: '3' },
        { name: 'Test', id: '4' },
        { name: 'Affiliate', id: '5' },
        { name: 'Admin 2', id: '6' }
      ],
      orgTypes: [
        { id: 1, name: 'Affiliate' },
        { id: 2, name: 'Advertiser' }
      ],
      newPartnershipForm: {
        orgId: ''
      }
    };
  },
  methods: {
    addNewPartnership() {
      this.loading = true;
      setTimeout(() => {
        this.error = 'This partnership already exists';
        this.loading = false;
      }, 500);
    }
  }
};
</script>

<style lang="scss">
.new-partnership-modal {
  .el-input__suffix-inner {
    @apply tw-hidden;
  }

  .org-select-popover {
    @apply tw-shadow-none tw-border-none;
  }
}

.app-select-popover {
  .el-select-dropdown__item {
    @apply tw-text-jb-ink tw-font-medium;
  }

  .el-select-dropdown__item.selected {
    @apply tw-text-jb-ink tw-font-semibold;
  }
}
</style>
