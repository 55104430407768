<template>
  <div class="organization-permissions">
    <div class="tw-mb-layout-2 tw-p-space-4 tw-bg-jb-indigo-lighter xs:tw-flex-row xs:tw-items-end tw-flex tw-flex-col tw-items-start tw-justify-between tw-rounded">
      <div class="xs:tw-w-auto tw-flex-grow tw-w-full">
        <app-text type="div" weight="medium" class="tw-text-jb-grey-700 tw-mb-space-1 tw-text-xs">
          Permissions
        </app-text>
        <app-input v-model="searchValue" clearable size="mini" placeholder="Search" class="tw-w-full">
          <template #prefix>
            <search-icon class="tw-w-space-4 tw-h-space-4 tw-text-jb-grey-400" />
          </template>
        </app-input>
      </div>

      <app-button
        v-if="$can($permissions.CREATE_NEW_PARTNERSHIP_BETWEEN_ORGS)"
        class="tw-bg-jb-indigo hover:tw-bg-jb-indigo-dark xs:tw-w-auto xs:tw-mt-0 tw-mt-space-4 xs:tw-ml-layout-3 tw-w-full tw-ml-0 tw-font-medium tw-text-white"
        :class="{['!tw-bg-jb-indigo-disabled !tw-cursor-not-allowed']: isNewPartnershipButtonDisabled }"
        :disabled="isNewPartnershipButtonDisabled"
        @click="isNewPartnershipModalOpen = !isNewPartnershipModalOpen">
        <div class="tw-w-max-content tw-flex tw-items-center">
          <plus-icon class="tw-w-space-3 tw-h-space-3 tw-mr-space-2" />
          <app-text weight="medium" size="sm" class="tw-text-white">
            New Partnership
          </app-text>
        </div>
      </app-button>
    </div>
    <div class="organization-permissions-body">
      <template v-if="partnershipData.length && !loading">
        <app-accordion v-model="selectedOrgId" name="id" :data="partnershipData">
          <template slot="title" slot-scope="row">
            <div class="tw-flex tw-justify-between tw-w-full">
              <div class="tw-flex tw-flex-col">
                <app-heading level="h6" weight="semi-bold" :class="{'!tw-text-jb-indigo': selectedOrgId === row.item.id}" class="tw-text-jb-ink tw-m-0">
                  {{ row.item.org_name }}
                </app-heading>
                <div class="tw-flex tw-items-center">
                  <user-icon class="tw-w-space-4 tw-h-space-4 tw-text-jb-grey-400 tw-mr-space-1" />
                  <app-text class="tw-text-jb-grey-400 md:tw-text-sm tw-font-semibold">
                    {{ row.item.partnership_type === 'receiving' ? row.item.owner_primary_contact_email : row.item.receiving_primary_contact_email }}
                  </app-text>
                </div>
              </div>
              <div class="tw-flex tw-items-center">
                <div class="tw-py-space-1 tw-px-space-2 xs:tw-flex tw-bg-jb-grey-50 tw-items-center tw-hidden">
                  <app-text weight="semi-bold" class="tw-text-jb-grey-700 md:tw-text-sm">
                    Organization
                  </app-text>
                </div>
                <div class="tw-ml-9">
                  <chevron-previous-icon :class="{['is-active']: selectedOrgId === row.item.id}" class="tw-w-space-2 tw-h-space-3 tw-text-jb-grey-400 chevron-icon" />
                </div>
              </div>
            </div>
          </template>
          <template slot="body" slot-scope="row">
            <div class="tw-bg-white-bg tw-p-space-4">
              <template v-if="row.item.permissionsLoading">
                <div v-for="i in 2" :key="`permission-list-${i}`" class="tw-flex tw-flex-col">
                  <skeleton-box class="tw-h-space-2 tw-mb-space-2 tw-w-20 tw-m-0" />
                  <skeleton-box class="tw-h-space-2 tw-mb-space-4 tw-m-0 tw-w-[200px]" />
                  <div class="tw-mb-space-4">
                    <div v-for="j in 2" :key="`permission-list-inner-${j}`" class="tw-p-space-4 tw-mb-space-2 tw-bg-white">
                      <skeleton-box class="tw-h-space-2 tw-mb-space-2 tw-w-20 tw-m-0" />
                      <skeleton-box class="tw-h-space-2 tw-mb-space-2 tw-m-0 tw-w-[200px]" />
                    </div>
                  </div>
                </div>
              </template>

              <template v-else-if="showPermissionsData(row.item)">
                <div v-for="(resource, key) in row.item.resources" :key="key" class="tw-mb-space-6">
                  <app-heading level="h6" weight="semi-bold" class="tw-text-jb-ink tw-m-0 tw-capitalize">
                    {{ resource.name }}
                  </app-heading>
                  <!-- <app-text size="sm" weight="medium" class="tw-text-jb-grey-700">
                    Lorem ipsum dolor sit amet, consectetur adipiscing el it, sed do eiusmod tempor incididunt ut labore et dolore magna al iqua.
                  </app-text> -->
                  <div class="tw-border-jb-grey-50 tw-mt-space-4 tw-border tw-border-solid tw-rounded">
                    <app-icon-card>
                      <template #desc>
                        <el-checkbox-group v-model="resource.selected" class="campaign-grid tw-grid">
                          <el-checkbox
                            v-for="resourceItem in resource.items"
                            :key="resourceItem.resource_id"
                            :label="resourceItem.resource_id"
                            checked
                            disabled
                            class="checkbox campaign-checkbox xs:tw-mb-0 tw-mb-space-1">
                            <span v-if="parseInt(resourceItem.all_resources)">{{ `All ${resourceItem.resource_type || 'campaign'}s` | capitalizeAll }}</span>
                            <span v-else>{{ resourceItem.resource_name }}</span>
                          </el-checkbox>
                        </el-checkbox-group>
                      </template>
                    </app-icon-card>
                  </div>
                </div>
                <div v-if="row.item.permissions.length" class="resources-section">
                  <app-heading level="h6" weight="semi-bold" class="tw-text-jb-ink tw-m-0">
                    Resources
                  </app-heading>
                  <!-- <app-text size="sm" weight="medium" class="tw-text-jb-grey-700">
                    Lorem ipsum dolor sit amet, consectetur adipiscing el it, sed do eiusmod tempor incididunt ut labore et dolore magna al iqua.
                  </app-text> -->
                  <app-accordion v-model="row.item.selectedPermission" :accordion="false" class="tw-mt-space-4" name="name" :data="row.item.permissions">
                    <template slot="title" slot-scope="permissionRow">
                      <div class="tw-flex tw-items-center tw-justify-between tw-w-full">
                        <div>
                          <app-heading level="h6" weight="semi-bold" :class="{'!tw-text-jb-indigo': row.item.selectedPermission ? row.item.selectedPermission.includes(permissionRow.item.name) : false}" class="tw-text-jb-ink tw-m-0 tw-capitalize">
                            {{ permissionRow.item.name }}
                          </app-heading>
                          <!-- TODO: Permission category description is not yet available, removing placeholder for now
                          <app-text type="div" size="sm" weight="medium" class="subtitle-resource tw-text-jb-grey-700">
                            Placeholder for permission category description
                          </app-text>
                        -->
                        </div>
                        <div class="tw-ml-9 tw-mr-space-4">
                          <chevron-previous-icon :class="{['is-active']: row.item.selectedPermission ? row.item.selectedPermission.includes(permissionRow.item.name) : false}" class="tw-w-space-2 tw-h-space-3 tw-text-jb-grey-400 chevron-icon" />
                        </div>
                      </div>
                    </template>
                    <template slot="body" slot-scope="permissionRow">
                      <el-checkbox-group v-model="permissionRow.item.selected" class="tw-auto-rows-auto tw-gap-x-space-4 resources-section-grid tw-grid tw-w-full tw-overflow-x-auto">
                        <div v-for="(item, index) in permissionRow.item.items" :key="item.id + '_' + index" class="resource-checkbox">
                          <el-checkbox :label="item.id" checked disabled class="checkbox xs:tw-mb-0 tw-mb-space-1 tw-capitalize">
                            {{ item.description | startCase }}
                          </el-checkbox>
                        </div>
                      </el-checkbox-group>
                    </template>
                  </app-accordion>
                </div>
                <div class="tw-mt-space-4 tw-flex tw-justify-end">
                  <app-button state="link" class="tw-mr-space-2 tw-text-jb-grey-700 hover:tw-font-semi-bold tw-font-medium tw-no-underline tw-bg-transparent" @click="selectedOrgId = ''">
                    Cancel
                  </app-button>
                  <div v-tippy="{ enabled: true, showOnInit: false, content: 'Editing is not yet allowed for this feature', arrow: true, followCursor: false }">
                    <app-button
                      disabled
                      :loading="isSaving"
                      class="tw-font-medium"
                      state="primary"
                      @click="savePermissions">
                      Save
                    </app-button>
                  </div>
                </div>
              </template>
              <empty-org-settings-state v-else />
            </div>
          </template>
        </app-accordion>

        <el-pagination
          small
          class="organization-permissions-pagination"
          :page-size="pagination.per_page"
          :current-page="parseInt(pagination.page)"
          layout="prev, pager, next"
          :total="pagination.result_count"
          @current-change="pageChange" />
      </template>
      <div v-for="i in 3" v-else-if="loading" :key="`permission-list-${i}`">
        <skeleton-box class="tw-h-layout-4 tw-mb-space-2 tw-w-full" />
      </div>
      <empty-org-settings-state v-else :is-search="!!searchValue" label="permissions" />
    </div>
    <new-partnership-modal v-if="isNewPartnershipModalOpen" :visible.sync="isNewPartnershipModalOpen" @cancel="isNewPartnershipModalOpen = false" />
  </div>
</template>

<script>
import AppText from '@/components/AppText';
import AppHeading from '@/components/AppHeading';
import AppButton from '@/components/AppButton';
import AppAccordion from '@/components/AppAccordion';
import NewPartnershipModal from '@/components/organization-settings/modals/NewPartnershipModal';
import UserIcon from '@/assets/svg/user-icon.svg';
import PlusIcon from '@/assets/svg/plus-icon.svg';
import ChevronPreviousIcon from '@/assets/svg/chevron-previous-icon.svg';
import AppIconCard from '@/components/AppIconCard';
import AppInput from '@/components/AppInput';
import SearchIcon from '@/assets/svg/search-icon.svg';
import EmptyOrgSettingsState from '@/components/organization-settings/EmptyOrgSettingsState';
import SkeletonBox from '@/components/skeletons/SkeletonBox';
import _groupBy from 'lodash/groupBy';
import _isEmpty from 'lodash/isEmpty';
import _mapValues from 'lodash/mapValues';
import _values from 'lodash/values';
import _debounce from 'lodash/debounce';

export default {
  name: 'OrganizationPermissions',
  components: {
    AppText,
    AppHeading,
    AppButton,
    EmptyOrgSettingsState,
    AppInput,
    SearchIcon,
    AppAccordion,
    SkeletonBox,
    UserIcon,
    ChevronPreviousIcon,
    PlusIcon,
    AppIconCard,
    NewPartnershipModal
  },
  props: {
    partnershipData: {
      type: Array,
      required: true
    },
    pagination: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      isSaving: false,
      selectedOrgId: '',
      searchValue: this.$route.query.name || '',
      isNewPartnershipModalOpen: false,
      resources: [],
      permissions: [],
      isNewPartnershipButtonDisabled: true, // TODO: temporarily disabled for initial release. remove once functionality is built in.
      debounceGetPartnerships: _debounce(this.getPartnerships, 32, { trailing: true })
    };
  },
  watch: {
    searchValue: {
      immediate: true,
      handler() {
        this.debounceGetPartnerships();
      }
    },
    selectedOrgId() {
      this.getPartnershipDetails();
    },
    partnershipData() {
      this.loading = false;
    }
  },
  methods: {
    getPartnerships(page = 1) {
      this.loading = true;
      this.$emit('get-partnerships', { search: this.searchValue, params: { page: page } });
    },
    pageChange(currentPage) {
      this.getPartnerships(currentPage);
    },
    getSelectedOrg() {
      return this.partnershipData.find((item) => item.id === this.selectedOrgId);
    },
    showPermissionsData(item) {
      return (!_isEmpty(item.permissions) || !_isEmpty(item.resources)) && !item.permissionsLoading;
    },
    getGroupedResponse(data, groupByKey) {
      return _values(_mapValues(_groupBy(data, groupByKey), (items, key) => ({
        selected: [],
        name: key,
        items
      })));
    },
    async getPartnershipDetails() {
      if (!this.selectedOrgId) return;
      const partnership = this.getSelectedOrg();
      try {
        this.$set(partnership, 'permissionsLoading', true);
        const response = await this.$api.permissions.fetchPartnershipDetails({ partnershipId: this.selectedOrgId });
        this.$set(partnership, 'resources', this.getGroupedResponse(response.resources, 'resource_type'));
        this.$set(partnership, 'permissions', this.getGroupedResponse(response.permissions, 'category'));
      } catch (error) {
        if (this.$api.isCancel(error)) return;
        this.$message({ message: error.message, type: 'error' });
      } finally {
        this.$set(partnership, 'permissionsLoading', false);
      }
    },
    savePermissions() {
      this.isSaving = true;
      setTimeout(() => {
        this.isSaving = false;
        this.selectedOrgId = '';
      }, 500);
    }
  }
};
</script>
<style lang="scss">
.organization-permissions {
  .el-checkbox__label {
    @apply tw-p-0;
  }

  &-pagination {
    @include pagination;
  }

  .el-collapse-item {
    @apply tw-mb-space-2 tw-w-full;

    &.is-active {
      border-left: theme('spacing.space-3px');

      @apply tw-border-solid tw-border-jb-indigo tw-rounded;
    }

    .chevron-icon {
      @apply tw-transform tw--rotate-90 tw-duration-300;

      &.is-active {
        @apply tw-rotate-90;
      }
    }
  }

  .el-collapse-item__content {
    @apply tw-p-0 tw-rounded;
  }

  .resources-section .el-collapse-item {
    @apply tw-border-solid tw-border tw-border-jb-grey-50 tw-rounded;
  }

  .campaign-grid {
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr))
  }

  .resources-section-grid {
    grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  }

  .resources-section .el-collapse-item__content {
    @apply tw-p-space-4;
  }

  .el-collapse-item__header {
    @apply tw-p-space-4;
  }

  .checkbox {
    @apply tw-flex tw-items-center tw-py-space-2px;
  }

  .subtitle-resource {
    @include line-clamp(2);
  }

  .campaign-checkbox {
    min-width: theme('spacing.28');
  }
}
</style>
